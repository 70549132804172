<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Istifadəçinin şifrəsini dəyiş</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="password"
                :error-messages="getErrors('password', $v.password)"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Şifrə"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="repeatPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Şifrə təkrarı"
                :error-messages="getErrors('repeatPassword', $v.repeatPassword)"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeModal"> Ləğv et</v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Yadda saxla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, minLength, sameAs} from "vuelidate/lib/validators";
import usersService from "@/services/usersService";

export default {
  validations: {
    password: {required, minLength: minLength(6)},
    repeatPassword: {required, sameAsPassword: sameAs("password")}
  },
  data: () => ({
    dialog: false,
    showPassword: false,
    id: null,
    password: null,
    repeatPassword: null
  }),
  methods: {
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors;
      switch (name) {
        case "password":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          !model.minLength && errors.push("Şifrə minimum 6 simvol olmalıdır");
          break;
        case "repeatPassword":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          !model.sameAsPassword && errors.push("Şifrələr üst-üstə düşməlidir");
          break;
        default:
          break;
      }
      return errors;
    },
    closeModal() {
      this.$v.$reset();
      this.dialog = false;
      this.password = null;
      this.repeatPassword = null;
    },
    save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(`!this.$v.$invalid`, !this.$v.$invalid)
        if (this.id) {
          usersService.changePassword({
            id: this.id,
            password: this.password,
            repeatPassword: this.repeatPassword
          }).then((response) => {
            if (response.status === 200) {
              this.closeModal();
              this.$eventBus.$emit("refetchUsers", true);
              this.$eventBus.$emit("notification", {
                type: "success",
                message: "İstifadəçi şifrəsi uğurla redaktə edildi",
              });
            } else {
              this.$eventBus.$emit("notification", {
                type: "error",
                message: "İstifadəçini şifrəsini redaktə etmək mümkün olmadı",
              });
            }
          });
        }
      }
    },
  },
  mounted() {
    this.$eventBus.$on("openPasswordModal", (data) => {
      this.dialog = true;
      if (data.id) {
        this.id = data.id;
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("openPasswordModal", null);
  },
};
</script>
