<template>
  <v-card class="my-4" outlined v-if="searchModal">
    <v-row class="pa-4">
      <v-col cols="4">
        <v-text-field
          @keyup="getUsers"
          v-model="searchForm.firstName"
          label="Ad"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          @keyup="getUsers"
          v-model="searchForm.lastName"
          label="Soyad"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          @keyup="getUsers"
          v-model="searchForm.userName"
          label="İstifadəçi adı"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          @keyup="getUsers"
          v-model="searchForm.email"
          label="Elektron poçt"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          @keyup="getUsers"
          v-model="searchForm.phoneNumber"
          label="Mobil nömrə"
        />
      </v-col>

      <v-col cols="4"></v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState({
      searchForm: (state) => state.user.searchForm,
    }),
    isSearchModalOpen() {
      return this.searchModal;
    },
  },
  props: {
    toggleSearchModal: {
      type: Function,
      required: true,
    },
    searchModal: {
      type: Boolean,
      required: true,
    },
    getUsers: {
      type: Function,
      required: true,
    },
  },
};
</script>
