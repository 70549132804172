<template>
  <v-dialog persistent v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.firstName"
                label="Ad"
                hide-details="auto"
                :error-messages="getErrors('firstName', $v.editedItem.firstName)"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.lastName"
                label="Soyad"
                hide-details="auto"
                :error-messages="getErrors('lastName', $v.editedItem.lastName)"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :error-messages="getErrors('email', $v.editedItem.email)"
                v-model="editedItem.email"
                label="Elektron poçt"
                hide-details="auto"
                type="email"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Mobil nömrə"
                hide-details="auto"
                v-model="editedItem.phoneNumber"
                :error-messages="getErrors('phoneNumber', $v.editedItem.phoneNumber)"
              />
              <p class="caption grey--text text-darken-1 mb-0">
                Məsələn: +99450XXXXXXX
              </p>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.userName"
                label="İstifadəçi adı"
                hide-details="auto"
                :error-messages="getErrors('userName', $v.editedItem.userName)"
              />
            </v-col>

            <v-col v-if="!editedItem.id" cols="12" sm="6" md="6">
              <v-text-field
                v-model="editedItem.password"
                :error-messages="getErrors('password', $v.editedItem.password)"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Şifrə"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeModal"> Ləğv et</v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Yadda saxla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required, minLength, email} from "vuelidate/lib/validators";
import usersService from "@/services/usersService";

export default {
  validations: {
    editedItem: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      phoneNumber: {required},
      userName: {required},
      password: {required, minLength: minLength(6)},
    },
  },
  data: () => ({
    dialog: false,
    showPassword: false,
    editedItem: {
      id: null,
      userName: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      password: null,
    },
  }),
  computed: {
    formTitle() {
      return !this.editedItem.id
        ? "Yeni istifadəçi əlavə et"
        : "İstifadəçi məlumatlarını dəyiş";
    },

  },
  methods: {
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors;
      switch (name) {
        case "firstName":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          break;
        case "lastName":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          break;
        case "email":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          !model.email && errors.push("E -poçt etibarlı deyil");
          break;
        case "phoneNumber":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          break;
        case "userName":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          break;
        case "password":
          !model.required && errors.push("Zəhmət olmasa bölməni doldurun");
          !model.minLength && errors.push("Şifrə minimum 6 simvol olmalıdır");
          break;
        default:
          break;
      }
      return errors;
    },
    closeModal() {
      this.$v.$reset();
      this.dialog = false;
      this.editedItem = {
        id: null,
        userName: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        password: null
      };
    },
    save() {
      this.$v.$touch();
      if (this.editedItem.id) {
        console.log("update");
        usersService.updateUser(this.editedItem).then((response) => {
          if (response.status === 200) {
            this.closeModal();
            this.$eventBus.$emit("refetchUsers", true);
            this.$eventBus.$emit("notification", {
              type: "success",
              message: "İstifadəçi uğurla redakte edildi",
            });
          } else {
            this.$eventBus.$emit("notification", {
              type: "error",
              message: "İstifadəçinini redakte etmək mümkün olmadı",
            });
          }
        });
      } else {
        if (!this.$v.$invalid) {
          console.log("create");
          usersService.createUser(this.editedItem).then((response) => {
            if (response.status === 200) {
              this.closeModal();
              this.$eventBus.$emit("refetchUsers", true);
              this.$eventBus.$emit("notification", {
                type: "success",
                message: "İstifadəçi uğurla yaradıldı",
              });
            } else {
              this.$eventBus.$emit("notification", {
                type: "error",
                message: "İstifadəçini yaratmaq mümkün olmadı",
              });
            }
          });
        }
      }
    },
  },
  mounted() {
    this.$eventBus.$on("openCreationModal", (data) => {
      this.dialog = true;
      if (data.id) {
        this.editedItem = data;
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("openCreationModal", null);
  },
};
</script>
