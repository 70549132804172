<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title class="text-h5">
        Silmək istədiyinizə əminsiniz?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModal">Imtina et</v-btn>
        <v-btn color="red darken-1" text @click="deleteUser">Sil</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import usersService from "@/services/usersService";

export default {
  data: () => ({
    dialog: false,
    userId: null
  }),
  methods: {
    closeModal() {
      this.dialog = false;
    },
    deleteUser() {
      usersService.deleteUser(this.userId).then((response) => {
        if (response.status === 200) {
          this.$eventBus.$emit("resetPage", true);
          this.closeModal();
          this.$eventBus.$emit("refetchUsers", true);
          this.$eventBus.$emit("notification", {
            type: "success",
            message: "Istifadəçi uğurla silindi",
          });
        } else {
          this.closeModal();
          this.$eventBus.$emit("refetchUsers", true);
          this.$eventBus.$emit("notification", {
            type: "error",
            message: "Istifadəçini silmək mümkün olmadı",
          });
        }
      });
    },
  },
  mounted() {
    this.$eventBus.$on("openConfirmationModal", (data) => {
      this.dialog = true;
      this.userId = data.id;
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("openConfirmationModal", null);
  },
};
</script>
