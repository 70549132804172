<template>
  <v-container>
    <v-row class="d-flex align-center container">
      <v-btn
        @click="searchModal = !searchModal"
        color="primary"
        class="my-4 mr-4"
      >
        <v-icon class="pr-2"> mdi-magnify </v-icon> Axtar
      </v-btn>
      <v-btn @click="resetSearch" v-if="searchModal" class="my-4">
        Təmizlə
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="openCreationModal" color="orange" dark>
        Yeni İstifadəçi
      </v-btn>
    </v-row>

    <SearchModal
      :getUsers="getUsers"
      :searchModal="searchModal"
      :toggleSearchModal="toggleSearchModal"
    />
    <CreationModal />
    <ConfirmationModal />
    <PasswordModal />
    <v-data-table
      :headers="headers"
      :items="users"
      :page="page"
      :server-items-length="totalCount"
      sort-by="firstName"
      class="elevation-1"
      @update:items-per-page="handleLimitChange"
      @update:page="handlePageChange"
      :footer-props="{
        'items-per-page-text': 'Sətir sayı',
        'items-per-page-all-text': 'Hamısı',
        pageText: '{0}-{1} / {2}',
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="openCreationModal(item)">
          <v-icon right class="mr-2">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn icon @click="openConfirmationModal(item)">
          <v-icon right class="mr-2">
            mdi-delete
          </v-icon>
        </v-btn>
        <v-btn icon @click="openPasswordModal(item)">
          <v-icon right class="mr-2">
            mdi-lock
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

import usersService from "@/services/usersService";
import CreationModal from "./components/CreationModal.vue";
import SearchModal from "./components/SearchModal.vue";
import ConfirmationModal from "./components/ConfirmationModal.vue";
import PasswordModal from "./components/PasswordModal";

export default {
  components: { CreationModal, SearchModal, ConfirmationModal, PasswordModal },
  data: () => ({
    page: 1,
    perPage: 10,
    searchModal: false,
    headers: [
      { text: "Ad", value: "firstName", sortable: false },
      { text: "Soyad", value: "lastName", sortable: false },
      { text: "İstifadəçi adi", value: "userName", sortable: false },
      { text: "Elektron poçt", value: "email", sortable: false },
      { text: "Mobil Nömrə", value: "phoneNumber", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {
      fullName: null,
      mobileNumber: null,
      contractNumber: null,
      building: null,
      apartmentNumber: null,
      firstPaymentDate: null,
      nextPaymentDay: null,
      paymentPeriod: null,
    },
    defaultItem: {
      fullName: null,
      mobileNumber: null,
      contractNumber: null,
      building: null,
      apartmentNumber: null,
      firstPaymentDate: null,
      nextPaymentDay: null,
      paymentPeriod: null,
    },
  }),
  validations: {
    statusDate: {
      required,
    },
    editedItem: {
      fullName: {
        required,
      },
      mobileNumber: {
        required,
      },
      contractNumber: {
        required,
      },
      building: {
        required,
      },
      apartmentNumber: {
        required,
      },
      firstPaymentDate: {
        required,
      },
      nextPaymentDay: {
        required,
      },
      paymentPeriod: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      searchForm: (state) => state.user.searchForm,
      users: (state) => state.user.users,
      totalCount: (state) => state.user.totalCount,
    }),
  },
  methods: {
    resetSearch() {
      this.$store.commit("user/SET_SEARCH_FORM", {
        offset: (this.page - 1) * this.perPage,
        limit: this.perPage,
        firstName: null,
        lastName: null,
        userName: null,
        phoneNumber: null,
        email: null,
      });
      this.getUsers();
    },
    handlePageChange(page) {
      this.page = page;
      this.searchEvaluationTypes();
    },
    handleLimitChange(limit) {
      this.limit = limit;
      this.perPage = limit > 0 ? limit : this.totalCount;
      this.getUsers();
    },
    toggleSearchModal() {
      this.searchModal = !this.searchModal;
    },
    getUsers() {
      this.$store.commit("user/SET_SEARCH_FORM", {
        offset: (this.page - 1) * this.perPage,
        limit: this.perPage,
        firstName: this.searchForm.firstName,
        lastName: this.searchForm.lastName,
        userName: this.searchForm.userName,
        email: this.searchForm.email,
        phoneNumber: this.searchForm.phoneNumber,
      });
      usersService.getUsers(this.searchForm).then(({ data }) => {
        this.$store.commit("user/SET_USERS", {
          totalCount: data.itemsCount,
          users: data.items,
        });
      });
    },
    openCreationModal(item) {
      this.$eventBus.$emit("openCreationModal", item);
      usersService.getUserById(item.id).then((response) => {
        this.$eventBus.$emit("openCreationModal", response.data);
      });
    },
    openConfirmationModal(item) {
      this.$eventBus.$emit("openConfirmationModal", item);
    },
    openPasswordModal(item) {
      this.$eventBus.$emit("openPasswordModal", item);
    }
  },
  created() {
    this.getUsers();
    this.$eventBus.$on("refetchUsers", () => this.getUsers());
  },
};
</script>
